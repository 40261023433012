import React from "react";
import { Fade } from "react-awesome-reveal";

export const Services = (props) => {
  return (

    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <Fade>
            <h2>Our Services</h2>
          </Fade>
          <Fade >
            <p>
              We have very capable software and firmware development, QA and technical support engineers with a broad experience in various areas of product development including applications, drivers, security toolkits and components (PKI, IPSEC, PKCS), network protocols (TCP/IP stack), real-time embedded systems, communication systems, including wireless and portable devices. Programming includes C, C++, C#, Objective C, Swift, Kotlin, Java, SQL, HTML/PHP/ASP, XML/XSL in .NET/Visual Studio, 32- and 64-bit Windows, Linux, SunOS and Solaris, VxWorks, OpenBSD, open source and proprietary real-time (RTOS) and embedded environments, including firmware development for external devices, mobile platforms including Android and iOS, as well as Assembler for BIOS and other low-level and performance-optimized systems and components. 
              We have experience in the area of the cloud computing such as AWS, MS Azure and Google Cloud.
              We widen our expertise in area of data science where we have experience of creating and maintaining of custom ML/DL models.
            </p>
          </Fade>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-4">
                {" "}
                <Fade>
                  <a href={d.href}><i className={d.icon}></i></a>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                  </div>
                </Fade>
              </div>
            ))
            : "loading"}
        </div>
        <span id="system-components"></span>
        <div style={{ marginTop: '100px' }}>
          <ol className="text-left">
            <Fade>
              <li>
                <span>System Components and Libraries:</span>
                <ul>
                  <li>System extensions and utilities including shell extensions, data compression, and data recovery</li>
                  <li>Device drivers including virtual device drivers and kernel-mode drivers for 32-bit and 64-bit Windows platforms and drivers for UNIX, Solaris and Linux</li>
                  <li>SPI and PP communication drivers for WinCE and Linux embedded platforms</li>
                  <li>NDIS/ODI drivers for different platforms</li>
                  <li>Winsock 2 Layer Service Providers</li>
                  <li>Linux Network device drivers, MTD drivers, Compact Flash drivers, I2C chip drivers when adjusting Linux kernel for non-standard embedded platforms</li>
                  <li>IEEE 802.11/802.16 wireless protocols based drivers for embedded Linux</li>
                  <li>Wireless security based on IEEE 802.11 and 802.11i standards</li>
                  <li>CSP with PKCS#11 support for Windows and Linux platforms</li>
                  <li>Platform abstraction “binary module” for secure BIOS solution</li>
                  <li>U-Boot Boot Loader components and extensions for ARM platforms</li>
                  <li>UEFI  applications and drivers for custom hardware platforms</li>
                  <li>ISO 7816/14443 smart card protocols based drivers</li>
                </ul>
              </li>
            </Fade>
            <span id="network"></span>

            <Fade>
              <li style={{ marginTop: '100px' }}>
                <span>Network and Network Management:</span>
                <ul>
                  <li>TCP/IP network components and protocol support for VxWorks, PSOS and OnTime embedded real-time operating systems as well as for Windows, Linux and Unix platforms</li>
                  <li>SNMP agent for embedded systems and SNMP enterprise network management applications for Windows and Solaris platforms</li>
                  <li>Level 2 and Level 3 protocols, serial communication, including routing, bridging and network security protocols</li>
                  <li>HTTP/HTTPS client modules based on customized mini TCP/IP (uIP and lwIP) stacks</li>
                </ul>
              </li>
            </Fade>
            <span id="sec-systems"></span>

            <Fade>
              <li style={{ marginTop: '100px' }}>
                <span>Security Systems and Applications:</span>
                <ul>
                  <li>PKI applications including server and client applications to support large variety of PKI operations and protocols; also server and client for SCEP, CMP, OCSP</li>
                  <li>IPSEC based and proprietary systems for site-to-site and remote access VPNs over IP, IPX, Frame Relay and X.25 networks; T1/T3 link encryption devices</li>
                  <li>PKI algorithms including DH key exchange, DSS and RSA digital signature, ECC-based digital signing and authentication</li>
                  <li>SSL and TLS protocols implementation</li>
                  <li>Symmetric algorithms: DES, TDES, AES and other in C and Java</li>
                  <li>Client software for Winsock 2 based TCP/IP secure access system</li>
                  <li>Web-based interface for network management with SSL</li>
                  <li>Access control system components including proxy server, secure gateway and client modules for Linux, Windows and Windows CE; application level authentication protocols</li>
                  <li>FIDO ecosystem and DigitalID</li>
                  <li>Secure element applications</li>
                </ul>
              </li>
            </Fade>
            <span id="wsa"></span>

            <Fade>
              <li style={{ marginTop: '100px' }}>
                <span>Web Services and Applications:</span>
                <ul>
                  <li>Cloud Consulting. Amazon Web Services (AWS), Google Cloud, Microsoft Azure</li>
                  <li>Cloud Migration Services. Migration planning and implementation</li>
                  <li>Web development. Java (Spring Boot/Framework, Servlet API), Python(django), DHTML(HTML/CSS/JavaScript), PHP, Apache/MySQL</li>
                  <li>Web content management application including server and browser plug-ins and policy server integrated with LDAP and Active Directory</li>
                  <li>Small footprint embedded HTTP server with OpenSSL-based SSL implementation</li>
                  <li>Single sign-on for Web applications, based on digital certificates and cookies</li>
                </ul>
              </li>
            </Fade>
            <span id="mobile"></span>
            <Fade>
              <li style={{ marginTop: '100px' }}>
                <span>Mobile Applications:</span>
                <ul>
                  <li>Custom RSS reader application. iPhone, iPad (objective C)</li>
                  <li>Certificate based user authentication and policy enforcement application. iPhone (objective C)</li>
                  <li>A set of applications for user authentication and secure communication with web servers. Android (Java/C)</li>
                </ul>
              </li>
            </Fade>
            <span id="ai"></span>
            <Fade>
              <li style={{ marginTop: '100px' }}>
                <span>Data Science:</span>
                <ul>
                  <li>AI/ML operations on Cloud</li>
                  <li>Data Analysis & Visualization</li>
                  <li>ML/DL Consulting and Implementation</li>
                </ul>
              </li>
            </Fade>
          </ol>
        </div>
      </div>
    </div>
  );
};
