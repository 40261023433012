import React from "react";
import { Fade } from "react-awesome-reveal";

export const Header = (props) => {
  return (
    <header id="header" style={{marginTop: '80px'}}>
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <Fade>
                  <div className="box">
                    <img className="main-logo" src="/img/logo.png" alt="Hylink Logo" />
                  </div>
                </Fade>
                <Fade>
                  <h1>
                    Hylink Technologies
                    <span></span>
                  </h1>
                </Fade>
                <Fade>
                  <p style={{ fontSize: '24px', fontWeight: '700' }}>We provide the <span style={{ backgroundColor: '#f46524' }}> BEST</span> solutions</p>
                  <a href="#services" className="btn btn-custom btn-lg page-scroll" >
                    Learn More
                  </a>{" "}
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
